import React, { useContext, useState, useEffect, useMemo } from 'react';
import ProductGrid from '../../organisms/ProductGrid/ProductGrid';
import Container from '../../atoms/Container/Container';
import { getStorage } from '../../../helpers/general';
import GraphQLContext from '../../../context/GraphQLProvider';

const RecentlyViewed = ({ currentProductId, limit, columns = 4, emptyFeedback = false, withContainer = false, title = '', withDivider = false, styles }) => {
  const recentProductsValue = getStorage('__trackRV');
  
  const recentProducts = useMemo(() => {
    let recentProductsData = [];
    if (recentProductsValue) {
      recentProductsData = JSON.parse(recentProductsValue);
      if (recentProductsData.indexOf(currentProductId) > -1) {
        recentProductsData.splice(recentProductsData.indexOf(currentProductId), 1);
      }
    }

    return recentProductsData;
  }, [recentProductsValue, currentProductId]);
  
  const graphQLContext = useContext(GraphQLContext);
  const [products, setProducts] = useState([]);
  const [queryRunning, setQueryRunning] = useState(false);
  const [queryComplete, setQueryComplete] = useState(false);

  useEffect(() => {
    if (
      recentProducts &&
      recentProducts.length > 0 &&
      !queryRunning &&
      !queryComplete
    ) {
      setQueryRunning(true);
      const query = graphQLContext && graphQLContext.query;
      query(
        `query($productIds: [Int!] = [], $width: Int = 300) {
            site {
              products(entityIds: $productIds) {
                edges {
                  node {
                    defaultImage {
                      url(width: $width)
                    }
                    entityId
                    name
                    path
                    variants(first: 1) {
                      edges {
                        node {
                          entityId
                        }
                      }
                    }
                    prices {
                      price {
                        value
                      }
                      retailPrice {
                        value
                      }
                      salePrice {
                        value
                      }
                    }
                  }
                }
              }
            }
          }`,
        { productIds: recentProducts, width: 300 }
      )
        .then(response => {
          if (response.data && response.data.site.products.edges.length > 0) {
            const productsData = response.data.site.products.edges;
            productsData.sort((a, b) => {
              return recentProducts.indexOf(a.node.entityId) - recentProducts.indexOf(b.node.entityId);
            });
            const list = limit ? productsData.slice(0, limit) : productsData;
            setQueryComplete(true);
            setQueryRunning(false);
            setProducts(list);
          }
        })
        .catch(error => {
          setQueryComplete(true);
          setQueryRunning(false);
          // console.log("fetch list error:", error);
        });
    }
  }, [
    recentProducts,
    graphQLContext,
    setProducts,
    queryRunning,
    setQueryRunning,
    queryComplete,
    setQueryComplete,
    products,
    limit
  ]);

  // const DisplayDivider = withDivider ? 

  const VariableContainer = !withContainer ? React.Fragment : (({ children }) => (
    <div className={styles.recentlyViewedProducts}>
      <Container>
        {withDivider && (withDivider)}
        {title && (<h4>{title}</h4>)}
        {children}
      </Container>
    </div>
  ));

  return (
    <>
      {products.length > 0 && (
        <VariableContainer>
          <ProductGrid
            products={products}
            columns={columns}
            loadInventory={true}
          />
        </VariableContainer>
      )}
      {products.length === 0 && emptyFeedback && (
        <VariableContainer>
          <p>You have not viewed any products in this browsing session.</p>
        </VariableContainer>
      )}
    </>
  );
};

export default RecentlyViewed;
